@import 'scss/_variables';
@import 'scss/_mixins';

.user-propgression-info {
  :global {
    .text-align-center {
      text-align: center;
    }
    .ant-card-body {
      height: 200px;
      .ant-row {
        height: 100%;
      }
    }
    .ant-divider-vertical {
      background: $border-user-profile;
      top: 10%;
      height: 80%;
    }
    .markdown {
      font-size: 24px;
    }
  }
}
