@import 'scss/_variables';
@import 'scss/_mixins';

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;

  max-width: 1087px;
  margin: 0 auto;

  :global {
    .my-masonry-grid_column {
      margin-bottom: 100px;
      padding-left: 1px; /* gutter size */
      background-clip: padding-box;
      margin-top: 30px;

      .mosaic-container {
        text-align: center;
        color: white;
        border-radius: 10px;
        margin: 10px 5px;
        position: relative;
        cursor: pointer;
        .category-image {
          width: 100%;
          opacity: 0.5;
        }
        &:hover .category-image {
          transition: transform 0.8s;
          opacity: 0.1;
          width: calc(100% - 2px);
          transform: scale(1.1);
        }

        .text-centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
