@import 'scss/_variables';
@import 'scss/_mixins';

.course-card {
  width: 250px;
  height: 540px;
  :global {
    .ant-card-cover {
      padding: 2px;

      img,
      .img-skeleton {
        object-fit: cover;
        height: 170px;
        border-radius: 10px;
        width: 100%;
        .ant-skeleton-image {
          width: 100%;
          height: 100%;
        }
      }
    }
    .ant-card-cover {
      background-color: $bg-card-body;
      border-radius: 5px;
    }
    .ant-card-body {
      background-color: $bg-card-body;
      border-radius: 5px;
      .ant-card-meta {
        .ant-col {
          padding-right: 0 !important;
          padding-left: 0 !important;
          .ant-typography.title {
            font-weight: 700;
            font-size: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .ant-card-meta-description {
          height: 2.5em;
          font-size: 14px;
        }
      }
      .ant-btn.mt-50 {
        border-radius: 5px;
      }
    }

    .category {
      font-size: 15px;
    }
    img.progressionImage,
    .progressionImage-placeholder {
      width: 100px;
      height: 100px;
    }

    .button-skeleton {
      width: 100%;
      .ant-skeleton-button {
        width: 100%;
      }
    }
  }
}
