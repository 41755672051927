@import "scss/_variables";
@import "scss/_mixins";

.user-profile {
  :global {
    .ant-tabs {
      .ant-tabs-nav-list {
        width: 196px;
        border: 1px solid $border-user-profile;
        .ant-tabs-tab {
          margin: 0 !important;
          &.photo-panel,
          &.privacy-panel {
            display: none;
          }
          .user-image {
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .ant-avatar {
              margin-bottom: 10px;
              .ant-avatar-string {
                font-size: 40px;
              }
            }
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }
      min-height: 699px;
      .ant-tabs-tab-disabled {
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .ant-tabs-tab-active {
        background-color: $border-user-profile;
        .ant-tabs-tab-btn {
          font-weight: 400;
          text-transform: initial;
        }
      }
      .site-layout {
        .ant-typography {
          text-align: center;
          padding: 25px;
        }
      }
      .ant-tabs-content-holder {
        border: 1px solid $border-user-profile;
      }
    }
  }
}
