@import 'scss/_variables';
@import 'scss/_mixins';

.webinar-grid {
  :global {
    .ant-card {
      position: relative;
      .ant-card-cover {
        opacity: 0.4;
        img {
          border-radius: 0;
          cursor: pointer;
        }
      }

      .ant-card-body {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        &:hover .plus {
          display: block;
        }
        .closed {
          position: absolute;
          bottom: 25%;
          left: 50%;
          text-align: center;
          transform: translate(-50%, -50%);
        }
        .plus {
          background-color: transparent;
          border: none;
          font-size: 54px;
          cursor: pointer;
          position: absolute;
          bottom: 20%;
          left: 50%;
          text-align: center;
          opacity: 0.6;
          transform: translate(-50%, -50%);
          display: none;
          &:hover {
            opacity: 1;
            transition: opacity 0.3s ease-out 0s;
          }
        }
        .color-closed {
          opacity: 0.7;
        }
      }
    }

    .slick-arrow {
      z-index: 100;
      opacity: 0.5;
      transition: opacity 0.1s ease-out 0s;

      &:before {
        display: block;
        font-size: 20px;
        color: #fff;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }

      &.slick-prev,
      &.slick-next {
        &::before {
          content: '';
          width: 33px;
          height: 57px;
          background-image: url('/assets/icon/chevron.svg');
          background-position: top center;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          bottom: 40px;
        }
      }
      &.slick-prev {
        left: 10px;
      }

      &.slick-next {
        right: 20px;

        &:before {
          transform: rotate(180deg);
        }
      }
    }

    .slick-dots {
      bottom: -30px;

      li {
        button {
          background-color: #fff;
        }
      }
    }
  }
}
