@import 'scss/_variables';
@import 'scss/_mixins';

.welcome-header {
  background-image: url('/assets/layout/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100vh - 264px);
  min-height: 350px;
  @media screen and (max-width: $size-md) {
    height: calc(100% - 64px);
    background-image: url('/assets/layout/background-md.png');
  }
  :global {
    .content-header {
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.48) -13.14%, rgba(0, 0, 0, 0.32) 100%);
      .app-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 100px 20px 40px 20px;

        @media screen and (max-width: $size-xs) {
          padding: 20px;
        }

        .ant-typography {
          &.welcome {
            @media screen and (max-width: $size-xs) {
              font-size: 2.8em;
            }
          }
          @media screen and (max-width: $size-xs) {
            font-size: 1.9em;
          }
        }
      }
    }
  }
}
