@import 'scss/_variables';
@import 'scss/_mixins';

.thumbnail {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  overflow-y: hidden;
  height: 492px;
  width: 735px;
  img {
    width: 100%;
  }
}
