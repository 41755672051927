@import 'scss/_variables';
@import 'scss/_mixins';

:global {
  .ant-layout-header {
    padding: 0 !important;
    @media screen and (max-width: $size-md) {
      position: sticky;
      top: 0;
      z-index: 2000;
    }
    .app-container {
      @media screen and (max-width: $size-md) {
        padding: 5px !important;
      }
      .ant-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 94px;

        .logo-col {
          height: 94px;
          margin-left: -16px;
          @media screen and (max-width: $size-md) {
            width: 160px;
            margin-left: 0;
          }
          .logo-brand {
            width: 200px;
            @media screen and (max-width: $size-md) {
              width: 160px;
            }
          }
        }
        .info-col {
          height: 94px;
          display: flex;
          align-items: flex-end;
          @media screen and (max-width: $size-md) {
            align-items: center;
          }
          .courses {
            font-size: 20px;
            color: $white;
            margin-right: 10px;
            @media screen and (max-width: $size-xs) {
              margin-right: 0;
            }
          }
          .ant-input-affix-wrapper {
            width: 279px;
            height: 30px;
            background-color: #252525;
            padding: 5px;
            border-radius: 5px;
          }
          .input-header {
            @media screen and (max-width: $size-md) {
              display: none;
            }
          }
          .user-drop {
            margin-right: 15px !important;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
