@import '~antd/dist/antd.less';

.ant-checkbox-input {
  opacity: 100 !important;
}

.answerBtn, .nextQuestionBtn {
  color: black;
}

div.quiz-result-filter select {
  color: black
}

@transparent-color: rgba(0, 0, 0, 0);@primary-color: @white;@font-family: "roboto-regular", sans-serif;@font-size-60: 3.75em;@font-size-40: 2.45em;@font-size-30: 1.95em;@font-size-25: 1.55em;@font-size-20: 1.25em;@font-size-16: 1em;@font-size-14: 0.85em;@typography-title-font-weight: 700;@typography-title-margin-top: 1em;@typography-title-margin-bottom: 1em;@font-size-base: 16px;@heading-1-size: @font-size-60;@heading-2-size: @font-size-40;@heading-3-size: @font-size-30;@heading-4-size: @font-size-25;@heading-5-size: @font-size-16;@heading-color: @white;@text-color: @white;@text-color-inverse: @black;@layout-body-background: @black;@layout-header-background: @black;@border-radius-base: 10px;@border-width-base: 2px;@body-background: @black;@component-background: @black;@btn-font-weight: 700;@btn-primary-bg: #252525;@btn-default-color: @black;@btn-default-bg: @white;@btn-shadow: 0 0 0 0;@btn-primary-shadow: 0 0 0 0;@btn-text-shadow: 0 0 0 0;@btn-height-base: 50px;@card-padding-base: 18px;@page-header-back-color: @white;@page-header-heading-title: @heading-3-size;@page-header-heading-sub-title: @font-size-20;@input-height-base: 30px;@input-height-lg: 57px;@input-placeholder-color: fade(#ABABAB, 75%);@input-color: #ABABAB;@input-border-color: #4D4D4D;