@import 'scss/_variables';
@import 'scss/_mixins';

.learning-path-strip {
  border: 2px solid $border-card;
  border-radius: 5px;
  padding: 18px;
  margin-bottom: 40px;
  max-width: 1118px;
  margin: 0 auto;
  margin-bottom: 50px;
  :global {
    .ant-page-header-heading-left {
      @media screen and (max-width: $size-md) {
        display: flex;
        flex-direction: column;
      }

      .ant-page-header-heading-title {
        margin-top: 10px;
        font-size: 30px;
        margin-bottom: 30px;
        margin-left: 8px;
        @media screen and (max-width: $size-xs) {
          font-size: 20px;
          margin-bottom: 0px;
          width: 250px;
        }
      }

      .ant-page-header-heading-sub-title {
        font-size: 20px;
        margin-left: 8px;
        @media screen and (max-width: $size-xs) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
