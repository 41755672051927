@import 'scss/_variables';
@import 'scss/_mixins';

.user-profile {
  :global {
    .app-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      .panel-title {
        text-align: center;
        margin: 35px 0;
      }
      .user-panel-form {
        width: 598px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .details-title {
          align-self: flex-start;
          font-size: 20px;
          margin: 10px 0;
        }
        .linkedin {
          display: none;
        }
        .ant-form-item {
          width: 100%;
          .ant-input {
            height: 40px;
            color: $white;
          }
          .ant-input-disabled {
            background: $black;
            cursor: default;
          }
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
            .user-panel-button {
              width: 153px;
            }
          }
        }
      }
    }
  }
}
