@import 'scss/_variables';
@import 'scss/_mixins';

.course-video {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 250px;

  :global {
    .caption {
      font-size: 20px;
    }
  }
}
