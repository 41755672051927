@import 'scss/_variables';
@import 'scss/_mixins';

.course-module-list {
  :global {
    .collapse {
      background-color: #070707;
      border-left: 1px solid #070707;
      border-right: 1px solid #070707;
      .ant-collapse-header {
        color: $white-color;
        padding: 30px 25px;
        font-size: 18px;
        .ant-collapse-arrow {
          font-size: 50px;
        }
      }
      .ant-collapse-content {
        background-color: #070707;
        color: $white-color;
        font-size: 18px;
        .ant-collapse-content-box {
          .ant-row {
            display: flex;
            align-items: center;
            .lesson-title {
              margin: 8px;
            }
          }
        }
      }

      .lesson-row {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
