@import 'scss/_variables';
@import 'scss/_mixins';

.webinar-card {
  :global {
    .container-title {
      height: 70px;
      .carousel-title {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .not-bold {
      font-size: 20px;
      font-weight: 400;
    }
  }
}
