// Roboto Font Face
@font-face {
  font-family: 'roboto-regular';
  src: url('~assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'roboto-bold';
  src: url('~assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  @media print {
    background-color: $white;
    color: $black;
    height: 600px;
  }
}

.app-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px;

}
