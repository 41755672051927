@import 'scss/_variables';
@import 'scss/_mixins';

.info {
  :global {
    .description.text {
      text-align: left;
      border-bottom: 1px solid $border-user-profile;
      padding-bottom: 50px;
    }
  }
}
