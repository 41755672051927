@import 'scss/_variables';
@import 'scss/_mixins';

.list-container {
  margin-top: 40px;
  :global {
    .info-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      width: 100%;
      .ant-progress {
        width: 558px;
        .ant-progress-outer {
          position: relative;
          .ant-progress-inner {
            height: 23px;
            border-radius: 5px;
            background-color: $black;
            border: 1px solid $white;
            .ant-progress-bg {
              background-color: $white;
              height: 23px !important;
              border-radius: 5px;
            }
          }
        }
      }
      .ant-btn {
        text-transform: uppercase;
        width: 220px;
        border-radius: 0;
        @media screen and (max-width: $size-xs) {
          margin-left: 15px;
          font-size: 14px;
          width: 250px;
        }
      }
    }
  }
}
