@import 'scss/_variables';
@import 'scss/_mixins';

.footer {
  background: $black-color;
  :global {
    background-color: #131313;
    margin-top: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0.1px 0.1px 2px 0.1px $border-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    .link-top {
      margin: 20px 0;
      .ant-col {
        display: flex;
        flex-wrap: wrap;
        a {
          margin: 0 30px;
          @media screen and (max-width: $size-xs) {
            margin: 0 5px;
          }
        }
      }
    }
    .link-bottom {
      margin-top: 10px;

      .trat {
        margin: 0 5px;
      }
      .ant-col {
        a {
          font-size: 14px;
        }
      }
    }
  }
}
