@import 'scss/_variables';
@import 'scss/_mixins';

.learning-path-list-title {
  padding: 25px 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  @media screen and (max-width: $size-xs) {
    padding: 20px 14px;
    margin-bottom: 15px;
  }
  :global {
    .ant-space {
      .ant-typography {
        @media screen and (max-width: $size-md) {
          font-size: 1em;
          width: 280px;
        }
        @media screen and (max-width: $size-xs) {
          font-size: 1em;
          width: 135px;
        }
      }
    }

    .ant-btn {
      img {
        width: 50px;
      }
    }
  }
}
