@import 'scss/_variables';
@import 'scss/_mixins';

.no-content {
  text-align: center;
  margin: 100px 200px 200px 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
