@import 'scss/_variables';
@import 'scss/_mixins';

.course-detail {
  :global {
    .author {
      font-weight: 400;
    }
    .module-title {
      font-weight: 400;
    }
    .module-divider {
      background-color: $white;
    }
  }
}
