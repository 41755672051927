// Buttons
.ant-btn {
  img {
    height: 100%;
    width: auto;
  }

  &.ant-btn-primary {
    border-color: #2e2e2e;
    text-transform: uppercase;
    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      color: $black;
    }
  }

  &.ant-btn-default {
    border-color: #2c2c2c;
  }

  &.ant-btn-ghost {
    border: none;
    outline: none;
    box-shadow: 0 0 0;
  }
}
.ant-row.btn {
  display: flex;
  justify-content: center;
  width: 100%;
  .subscribe {
    margin-left: 25px;
    margin-top: 30px;
    font-weight: 400;
    width: 280px;
    border-radius: 5px;
  }
}
// Card
.ant-card {
  &.ant-card-bordered {
    border-color: $border-card;
  }
}

// Page Header
.ant-page-header {
  padding: 0;

  .ant-page-header-heading-left {
    align-items: baseline;

    .ant-page-header-heading-sub-title {
      color: $white;
    }
  }
  &.direction-column {
    .ant-page-header-heading {
      .ant-page-header-heading-left {
        flex-direction: column;
      }
    }
  }
}
.ant-page-header.direction-column {
  margin-left: 40px;
  @media screen and (max-width: $size-xs) {
    margin-left: 0;
  }
}

// Input
.ant-input {
  border: 1px solid #4d4d4d;
  border-radius: 5px;

  &.ant-input-lg {
    border-radius: 10px;
    font-size: 20px;
  }

  &.light {
    background-color: #fff;
    color: rgba($black, 0.6);
  }
  &.input-header {
    height: 30px;
    margin-top: 15px;
    background-color: #252525;
  }
  &.login {
    background-color: $bg-login-input;
    width: 773px;
    border-radius: 0;
    border: 1px solid #434343;
  }
}

.ant-input-affix-wrapper {
  border: none;
  padding: 12px 24px;
  .ant-input {
    background-color: transparent;
  }
  .ant-input-prefix {
    margin-right: 15px;
  }
  &.light {
    background-color: #fff;
    color: rgba($black, 0.6);
  }
}

.ant-layout-header {
  height: 94px;
}

.ant-dropdown li.ant-menu-item.ant-menu-item-selected {
  background-color: $border-user-profile;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $border-user-profile;
}

.pageHeader-skeleton {
  .ant-skeleton-title {
    height: 32px;
  }
}

.ant-col.course-card-container.ant-col-xs-24.ant-col-md-12.ant-col-lg-6 {
  display: flex;
  justify-content: center;
}
.ant-page-header-heading-left {
  .ant-page-header-heading-title {
    @media screen and (max-width: $size-xs) {
      font-size: 1.5em;
    }
  }
}

.direction-column {
  margin-top: 60px;
  .ant-page-header-heading-title {
    font-size: 40px;
    margin-bottom: 10px;
    @media screen and (max-width: $size-xs) {
      font-size: 28px;
    }
  }
  .ant-page-header-heading-sub-title {
    font-size: 25px;
    @media screen and (max-width: $size-xs) {
      font-size: 23px;
    }
  }
}
.ant-tabs-tab.ant-tabs-tab-active {
  border: none;
  .ant-tabs-tab-btn {
    font-weight: 700;
    text-transform: uppercase;
  }
}
.ant-tabs-tab.no-upp {
  .ant-tabs-tab-btn {
    font-weight: 400;
    text-transform: initial;
  }
}
.ant-select-arrow {
  color: $white;
  font-size: 18px;
  right: 20px;
}
.ant-select-selection-placeholder {
  color: $white;
}
.ant-tabs-ink-bar {
  display: none;
}
.bg-select {
  background-color: $bg-filter;
  &:hover {
    color: $black;
  }
}
.icon-grid {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 100;
  width: 26px;
}

.ant-select-item-option-active {
  background-color: $bg-filter !important;
  color: $white !important;
  &:hover {
    background-color: $border-color !important;
  }
}
.ant-typography.course-category {
  margin-left: 60px;
  margin-top: 100px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $bg-filter;
 }
 .ant-menu-item.ant-menu-item-active {
   background-color: $border-user-profile;
 }
 .ant-picker-calendar-date-today {
    &::before {
    display: none;
  }
 }
