@import 'scss/_variables';
@import 'scss/_mixins';

.no-learning-path {
  :global {
    margin: 100px 0;
    @media screen and (max-width: $size-xs) {
      margin: 70px 0;
    }
    .ant-typography {
      @media screen and (max-width: $size-xs) {
        font-size: 1.1em;
      }
    }
  }
}
