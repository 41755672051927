@import 'scss/_variables';
@import 'scss/_mixins';

.course-progression-info {
  :global {
    .ant-card-body {
      height: 200px;
      display: flex;
      flex-direction: column;
    }
    .info-row {
      flex: 1;
      align-items: center;
    }
    .title {
      font-size: 24px;
      font-weight: 400;
    }
    .ant-divider-horizontal {
      background: $border-user-profile;
      width: 40%;
      min-width: 40%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .completed-hours {
      p {
        margin-bottom: 0;
      }
    }
  }
}
