@import 'scss/_variables';
@import 'scss/_mixins';

.thumbnail-course {
  margin-top: 20px;
  margin-bottom: 50px;
  :global {
    .thumb {
      width: 100%;
      height: 492px;
      object-fit: cover;
    }
  }
}
