@import 'scss/_variables';
@import 'scss/_mixins';

.notifications-profile {
  :global {
    .app-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      .panel-title {
        text-align: center;
        margin: 35px 0;
        margin-bottom: 100px;
      }
      .noNotifications {
        margin-top: 150px;
      }
    }
  }
}
