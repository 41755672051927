@import 'scss/_variables';
@import 'scss/_mixins';

.password-profile {
  :global {
    .app-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      .panel-title {
        text-align: center;
        margin: 35px 0;
      }
      .password-panel-form {
        width: 598px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        .details-title {
          align-self: flex-start;
          margin: 10px 0;
        }
        .ant-form-item {
          width: 100%;
          .ant-input-password {
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
            color: $white;
            border: 1px solid $border-user-profile;
            border-radius: 5px;
            &.ant-input-affix-wrapper-focused {
              border: 1px solid $white !important;
            }
            .anticon {
              color: $border-user-profile;
            }
          }
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;

            .user-panel-button {
              width: 153px;
            }
          }
        }
      }
    }
  }
}
