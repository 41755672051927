// VARIABLES
//////////////////////////////////////////////////////////////////////////
//Font
$font-family-regular: 'roboto-regular', sans-serif;
$font-family-bold: 'roboto-bold', sans-serif;
$font-size-60: 3.75em;
$font-size-40: 2.45em;
$font-size-30: 1.95em;
$font-size-25: 1.55em;
$font-size-20: 1.25em;
$font-size-16: 1em;
$font-size-14: 0.85em;

// Color
$black: #000;
$white: #fff;
$primary-color: #3fc9c5;
$text-color: #000000;

$bg-login-black: rgba(0, 0, 0, 0.6);
$bg-login-input: rgba(0, 0, 0, 0);
$bg-card-body: #131313;
$bg-filter: #252525;

$border-color: #9a9a9a;
$border-user-profile: #434343;
$border-card: #212121;
$black-color: #000000;
$white-color: #ffffff;

$red-color: #fc3c3c;
$blue-color: #43a1ff;
$green-color: #40c980;
$orange-color: #c98040;

$light-blue-chart-color: #16befc;
$blue-chart-color: #0062ff;
$red-chart-color: #f50000;
$green-chart-color: #3eff3e;

$trade-blue: #00aaff;
$trade-gray: #7d7d7d;
$trade-green: #40ff40;

// Breakpoints
$size-xs: 480px;
$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;
$size-xxl: 1600px;
