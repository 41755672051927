@import 'scss/_variables';
@import 'scss/_mixins';

.description {
  margin-bottom: 100px;
  :global {
    .text {
      width: 100%;
      font-size: 20px;
    }
  }
}
