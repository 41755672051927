// TYPOGRAPHY
//////////////////////////////////////////////////////////////////////////
div.ant-typography {
  font-size: $font-size-20;
  font-weight: 400;
}

div.ant-typography,
span.ant-typography {
  font-size: $font-size-14;
  font-weight: 400;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  font-family: $font-family-bold;
  margin: 0;
}

div.ant-typography,
span.ant-typography {
  font-family: $font-family-regular;
  margin: 0;

  &.bold {
    font-family: $font-family-bold;
    font-weight: 700;
  }
}

.center {
  text-align: center;
}
