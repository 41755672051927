@import 'scss/_variables';
@import 'scss/_mixins';

.dashboard-page {
  :global {
    .tab-container {
      position: relative;
      .toggle-button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}


