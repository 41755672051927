@import 'scss/_variables';
@import 'scss/_mixins';

.webinar-edition {
  :global {
  .ant-list {
    margin-top: 30px;
    .ant-list-header {
      border-bottom: none;
      margin-left: 50px;
      .ant-typography {
        font-size: 20px;
        font-weight: 700;
      }

    }
    .free-seats-container {
      margin-left: 30px;
      padding-left: 20px;
      display: flex;
      width: 368px;
      justify-content: flex-start;
      font-size: 20px;
      border-bottom: 1px solid $border-user-profile;
      .free-seats {
        min-width: 200px;
        margin-left: 20px;
        cursor: pointer;
        text-transform: capitalize;
      }
      .free-seats.select {
        font-weight: 700;
      }
      .anticon {
        font-size: 25px;
        margin-left: 5px;
      }
    }

    .free-seats-container-no-capitalize {
      margin-left: 30px;
      padding-left: 20px;
      display: flex;
      width: 368px;
      justify-content: flex-start;
      font-size: 20px;
      border-bottom: 1px solid $border-user-profile;
      .free-seats {
        min-width: 200px;
        margin-left: 20px;
        cursor: pointer;
      }
      .free-seats.select {
        font-weight: 700;
      }
      .anticon {
        font-size: 25px;
        margin-left: 5px;
      }
    }

  }
  .ant-picker-calendar {
    height: 360px;
    width: 368px;
    margin-top: 25px;
    margin-left: 25px;
    border: 1px solid $border-user-profile;
    border-radius: 0;
    position: relative;
    .row-date {
      .col-date {
        width: 370px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $bg-filter;
        .date {
          font-size: 22px;
          text-transform: capitalize;
        }
      }

    }
    .ant-picker-calendar-header {
      background-color: $bg-filter;
      border-radius: 0;
      height: 75px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      .ant-select {
        .ant-select-arrow {
          display: none;
        }
        .ant-select-selector {
          border: none;
          background-color: transparent;
          font-size: 22px;
          .ant-select-selection-item {
            padding-right: 0;
          }
        }
      }
      .ant-radio-group {
        display: none;
      }
    }
    .ant-picker-panel {
      border-top: none;
      margin-top: 10px;
      thead {
        tr {
          th {
            font-size: 20px;
          }
        }
      }
      .ant-picker-cell {
        font-size: 20px;

      }
      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          border-radius: 0;
        }
      }

    }

  }


}
}
