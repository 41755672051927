@import 'scss/_variables';
@import 'scss/_mixins';

.course-progression-grid {
  max-width: 1116px;
  margin: 0 auto;
  :global {
    .ant-col.container-card {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
