@import 'scss/_variables';
@import 'scss/_mixins';

.webinar-detail {
  padding-bottom: 200px;
  :global {
    .ant-tabs {
      margin-top: 20px;
      .ant-tabs-nav {
        &::before {
          display: none;
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            text-transform: capitalize;
          }
        }
        .ant-tabs-tab-btn {
          font-size: 25px;
        }
      }
      .ant-tabs-content {
        margin-top: 20px;
        .description {
          width: 674px;
          margin-top: 20px;
          font-size: 20px;
        }
      }
    }
  }
}
